<template>
  <div class="banner">
    <div class="absolute">
      <div class="content">
        <div class="carousel">
          <a-carousel autoplay arrows :dots="false" effect="fade">
            <template #prevArrow>
              <div class="carousel-arrow-l">
                <LeftOutlined class="icon" />
              </div>
            </template>
            <template #nextArrow>
              <div class="carousel-arrow-r">
                <RightOutlined class="icon" />
              </div>
            </template>
            <div
              class="carousel-item"
              v-for="(item, index) in dataSource"
              :key="index"
            >
              <div
                class="img"
                :style="{
                  backgroundImage: 'url(' + item.coverPath + ')',
                  cursor:
                    (item.jumpType == 1 && item.jumpUrl == '') ||
                    (item.jumpType == 2 && item.resourceId == 0)
                      ? 'default'
                      : 'pointer',
                }"
                @click="jump(item)"
              ></div>
            </div>
          </a-carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Carousel",
  props: {
    dataSource: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  setup(_, { emit }) {
    const jump = (data) => {
      emit("jump", data);
    };

    return {
      jump,
    };
  },
};
</script>

<style lang="less" scoped>
.banner {
  height: 320px;
  position: relative;
  .absolute {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .mixinFlex(center; center);
  }
  .content {
    width: 100vw;
    max-width: 1920px;
    min-width: 1200px;
    height: 320px;
    overflow: hidden;
    margin: 0 auto;
    background-color: #fff;
    .carousel {
      height: 320px;
      &-item {
        width: 100%;
        height: 320px;
        .img {
          width: 100%;
          height: 100%;
          background-position: 50%;
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
      .carousel-arrow-l,
      .carousel-arrow-r {
        width: 36px;
        height: 36px;
        top: calc(50% - 9px);
        border-radius: 50%;
        text-align: center;
        background-color: #000;
        padding: 6px 0;
        z-index: 1;
        opacity: 0.3;
        transition: opacity 0.3s ease;
        .icon {
          color: #fff;
          font-size: 24px;
          height: 24px;
        }
        &:hover {
          opacity: 0.5;
        }
      }
      .carousel-arrow-l {
        left: 12px;
      }
      .carousel-arrow-r {
        right: 12px;
      }
    }
  }
}
</style>
